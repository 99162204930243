:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}



html, body, #app, #root{
  height: 100%;
  width: 100%;
  padding: 0%;
  text-align: center;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}


.spacer {
  height: 100vh;
}

.logo {
  height: 4em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.app {
  position: relative;
}

.content {
  position: relative;
  z-index: 2;
}

.scrolling-text {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 1em;
  margin: 0 auto;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.scrolling-text h1 {
  margin: 0.5em 0;
  color: white;
}

.scrolling-text.visible {
  opacity: 1;
}

.image-element {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}

.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.animate-out {
  opacity: 0;
  transform: translateY(-20px);
}

.image-wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.image-wrapper img {
  max-width: 10px;
  min-width: 10px;
  height: auto;
  display: block;
  object-fit: contain; /* Ensures the image is scaled properly */
}
.image-container {
  max-width: none;
  width: 100%;
  position: relative;
  margin: 69px 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.image-container img {
  border-radius: 35px;
  max-width: 80vw;
  max-height: 80vh;
  width: auto;
  height: auto;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 10;
}

.app {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1
}
.content {
  position: relative;
  z-index: 2;
}

.move-left img {
  animation: moveLeft 10s linear infinite alternate;
}

.move-right img {
  animation: moveRight 10s linear infinite alternate;
}

@keyframes moveLeft {
  0% {
    transform: translateX(-100%) rotate(var(--rotate));
  }

  100% {
    transform: translateX(100%) rotate(var(--rotate));
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(100%) rotate(var(--rotate));
  }

  100% {
    transform: translateX(-100%) rotate(var(--rotate));
  }
}

.title {
  font-size: 8em;
  font-weight: 700;
  color: transparent;
  
  /* Set text color to transparent */
  margin: 0.5em 0;
  background-image: linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
  /* Rainbow colors */
  -webkit-background-clip: text;
  /* Clip the background to the text */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Make text transparent */
  text-shadow: #1a1a1a  -2px 2px 20px;

}




@media (max-width: 768px) {
  body {
    padding: 1rem;
    /* Adjust padding for smaller screens */
  }

  h1 {
    font-size: 2em;
    /* Adjust heading size for smaller screens */
  }

  .spacer {
    height: 500px;
    /* Adjust spacer height for smaller screens */
  }

  /* Add more adjustments as needed */
}